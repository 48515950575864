import axiosConfig from './axiosConfig';
import {camelizeKeys, decamelizeKeys} from 'humps';

const authApi = {
    login: async (credentials) => {
        const response = await axiosConfig.post('/login', decamelizeKeys(credentials));
        return camelizeKeys(response.data);
    },
    register: async (userData) => {
        const response = await axiosConfig.post('/register', decamelizeKeys(userData));
        return camelizeKeys(response.data);
    },
    getUser: async () => {
        const response = await axiosConfig.post('/user-detail-by-token');
        return camelizeKeys(response.data);
    },
    logout: async () => {
        await axiosConfig.post('/logout');
    },
    refreshToken: async (refreshToken) => {
        const response = await axiosConfig.post('/refresh-token', {refresh_token: refreshToken});
        return camelizeKeys(response.data);
    }
};

export default authApi;
