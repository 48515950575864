import React, {useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Button, Spinner, Form} from "react-bootstrap";
import Modal from "../common/Modal";
import {
    addSecondsIfMissing,
    convertTimeToHIS,
    createTodayDateWithTime, timeToSeconds
} from '../../utils/mainUtils';
import {authStore} from "../../stores/authStore";
import {useUsersStore} from "../../stores/usersStore";
import Select from "react-select";
import {format} from "date-fns";

const BreakManagementAdd = observer(({
                                         show,
                                         onClose,
                                         onAdd,
                                         onEdit,
                                         isSubmitting,
                                         store,
                                         data = null,
                                         recordDateTime
                                     }) => {
    const usersStore = useUsersStore();

    const initialModelData = {
        userId: '',
        startDateTime: '',
        endDateTime: ''
    };

    const initialErrors = {
        userId: '',
        startDateTime: '',
        endDateTime: ''
    };

    const userOptions = usersStore.users.map(item => ({
        value: item.id,
        label: item.firstName,
    })) || [];

    const [modelData, setModelData] = useState(initialModelData);
    const [errors, setErrors] = useState(initialErrors);
    const currentTime = format(new Date(), 'HH:mm:ss');

    useEffect(() => {
        if (!show) {
            setModelData(initialModelData);
            setErrors(initialErrors);
            store.resetErrorsStore();
        }
        usersStore.getListUsers();
    }, [show]);

    const handleDateChange = (e) => {
        const {name, value} = e.target;
        setModelData(prevData => ({...prevData, [name]: value}));
    }

    const handleTimeChange = (e) => {
        const {name, value} = e.target;
        const formatedTime = value ? convertTimeToHIS(value) : '';
        setModelData(prevData => ({...prevData, [name]: formatedTime}));
    }

    const validateForm = async () => {
        const validationPromises = Object.keys(modelData).map(fieldName =>
            validateField(fieldName, modelData[fieldName])
        );
        const validationResults = await Promise.all(validationPromises);

        return validationResults.every(error => !error);
    }

    const handleFilterChange = (event, field) => {
        const {value} = event.target;

        setModelData(prevData => ({...prevData, [field]: value}));
    };

    const handleCustomSelectChange = (selectedOptions, column) => {
        let itemId = '';

        if (selectedOptions) {
            itemId = selectedOptions?.value ?? '';
        }

        setModelData(prevData => ({...prevData, [column]: itemId}));
    };

    const validateField = (name, value) => {
        return new Promise((resolve) => {
            let errorMessage = '';
            switch (name) {
                case 'userId':
                    if (!value) {
                        errorMessage = 'Employee Name is required';
                    }
                    break;
                case 'startDateTime':
                    if (!value) {
                        errorMessage = 'Started is required';
                    } else if (
                        timeToSeconds(addSecondsIfMissing(value)) > timeToSeconds(addSecondsIfMissing(currentTime))
                    ) {
                        errorMessage = 'Started cannot be later than the current time';
                    }
                    break;
                case 'endDateTime':
                    if (!value) {
                        errorMessage = 'Finished is required';
                    } else if (modelData.startDateTime && new Date(createTodayDateWithTime(value)) <= new Date(createTodayDateWithTime(modelData.startDateTime))) {
                        errorMessage = '"Finished must be later than Start Time"';
                    } else if (
                        timeToSeconds(addSecondsIfMissing(value)) > timeToSeconds(addSecondsIfMissing(currentTime))
                    ) {
                        errorMessage = 'Finished cannot be later than the current time';
                    }
                    break;
                default:
                    break;
            }

            setErrors(prevErrors => ({...prevErrors, [name]: errorMessage}));
            resolve(errorMessage);
        });
    };

    const сalculationTotal = (start, end) => {
        const date1 = createTodayDateWithTime(start);
        const date2 = createTodayDateWithTime(end);
        const diffInMs = date2 - date1;
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

        if (diffInHours >= 0 && diffInMinutes >= 0) {
            return `${String(diffInHours).padStart(2, '0')}:${String(diffInMinutes).padStart(2, '0')}`;
        }

        return '00:00';
    }

    const handleBlur = (fieldName, value) => {
        validateField(fieldName, value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = await validateForm();
        if (isValid) {
            try {
                if (data) {
                    await onEdit(modelData.id, modelData);
                } else {
                    await onAdd(modelData);
                }
                setModelData(initialModelData);
                setErrors(initialErrors);
            } catch (error) {
            }
        } else {
            console.log('Form validation failed');
        }
    };

    return (
        <Modal show={show} onClose={onClose} title={data ? 'Edit tracked time' : 'Add tracked time'}>
            <Form>
                <Form.Group controlId="formEmployeeName">
                    <Form.Group controlId="formEmployeeName">
                        <Form.Label className={'label-in-modal'}>Employee Name</Form.Label>
                        <div className="w-100">
                            <Select
                                isClearable
                                value={userOptions.find(option => option.value == modelData.userId) || null}
                                onChange={(e) => handleCustomSelectChange(e, 'userId')}
                                options={userOptions}
                                className={`basic-multi-select custom-select custom-select-in-modal w-100 ${errors.userId ? 'is-invalid select-is-invalid' : ''}`}
                                onBlur={() => handleBlur('userId', modelData?.userId)}
                                name="selectedFilter"
                                classNamePrefix=""
                            />
                            {errors.userId && (
                                <div className="invalid-feedback">
                                    {errors.userId}
                                </div>
                            )}
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formrecordTime">
                        <Form.Label className={'label-in-modal'}>Started</Form.Label>
                        <Form.Control
                            className={'select-in-modal'}
                            type="time"
                            name="startDateTime"
                            value={modelData.startDateTime ? convertTimeToHIS(modelData.startDateTime) : ''}
                            onChange={handleTimeChange}
                            onBlur={() => handleBlur('startDateTime', modelData.startDateTime)}
                            required
                            isInvalid={!!errors.startDateTime}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.startDateTime}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formrecordTime">
                        <Form.Label className={'label-in-modal'}>Finished</Form.Label>
                        <Form.Control
                            className={'select-in-modal'}
                            type="time"
                            name="endDateTime"
                            value={modelData.endDateTime ? convertTimeToHIS(modelData.endDateTime) : ''}
                            onChange={handleTimeChange}
                            onBlur={() => handleBlur('endDateTime', modelData.endDateTime)}
                            required
                            isInvalid={!!errors.endDateTime}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.endDateTime}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formRecordDateTime">
                        <Form.Label className={'label-in-modal'}>Total</Form.Label>
                        <label className={'label-total-time'}>{modelData.startDateTime && modelData.endDateTime ? сalculationTotal(modelData.startDateTime, modelData.endDateTime) : '00:00'}</label>
                    </Form.Group>
                </Form.Group>
            </Form>
            <Button
                className={'button-save mt-2'}
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                {isSubmitting ? <Spinner as="span" animation="border" size="sm"/> : data ? "Edit" : "Save"}
            </Button>
        </Modal>
    );
});

export default BreakManagementAdd;
