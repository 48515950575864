import React, {useEffect} from 'react';
import {Toast, ToastContainer} from 'react-bootstrap';

const FlashMessage = ({message, variant, onClose}) => {
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                onClose();
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [message, onClose]);

    if (!message) return null;

    return (
        <ToastContainer className="toast-container p-3">
            <Toast onClose={onClose} bg={variant} show={true}>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default FlashMessage;