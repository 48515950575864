import {makeObservable, observable, runInAction} from "mobx";
import BaseStore from "./baseStore";
import {createContext, useContext} from "react";
import _ from "lodash";
import usersApi from "../api/usersApi";

class UsersStore extends BaseStore {
    users = [];

    constructor() {
        super();
        this.api = usersApi;

        makeObservable(this, {
            users: observable,
        });
    }

    loadData() {
        super.loadData(this.api);
    }

    resetStore() {
        super.resetStore();
    }

    resetErrorsStore() {
        super.resetErrorsStore();
    }

    addModel(model) {
        return super.addModel(this.api, model);
    }

    editModel(id, model) {
        return super.editModel(this.api, id, model);
    }

    deleteModel(id) {
        super.deleteModel(this.api, id);
    }

    async getListUsers() {
        try {
            const queryParams = {
                sort_by: 'id',
                sort_dir: this.sortOrder,
                page: 1,
                pagination_size: 9999999999,
                search_term: '',
                ..._.omitBy(this.filters, value => value === undefined || value === null || value === "")
            };

            const response = await this.api.getUsersListMinimal(queryParams);

            runInAction(() => {
                this.users = response.data.data ?? response.data;
            });
        } catch (error) {
            if (error?.code === 'ERR_CANCELED') {
                return
            }

            runInAction(() => {
                this.errors = error?.response?.data?.message ?? 'error';
                return error?.response?.data?.message ?? 'error';
            });
            console.error("Loading list failed", error);
        } finally {
        }
    }
}


const UsersStoreContext = createContext(new UsersStore());
export const useUsersStore = () => useContext(UsersStoreContext);

export default UsersStoreContext;