import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Nav} from "react-bootstrap";
import iconFolder from "../../assets/images/icons/folder-text.svg";
import timeIcon from "../../assets/images/icons/time-icon.svg";
import expensesIcon from "../../assets/images/icons/expenses-icon.svg";
import leavesIcon from "../../assets/images/icons/leaves-icon.svg";
import remoteWorkIcon from "../../assets/images/icons/remote-work-icon.svg";
import usersIcon from "../../assets/images/icons/users-icon.svg";
import departmentsIcon from "../../assets/images/icons/departments-icon.svg";
import listReceiptCcon from "../../assets/images/icons/list-receipt-icon.svg";
import clockTimeIcon from "../../assets/images/icons/clock-time-icon.svg";
import keyIcon from "../../assets/images/icons/key-icon.svg";


import {authStore} from '../../stores/authStore';

const SidebarListPages = observer(() => {
    const permissions = authStore.permissions;
    const location = useLocation();

    const isActiveMenu = (url) => {
        const currentPath = location.pathname;

        if (currentPath === url) {
            return ' selected-side-menu';
        }

        if (currentPath.startsWith(url) && (currentPath.length === url.length || currentPath[url.length] === '/')) {
            return ' selected-side-menu';
        }

        if (currentPath === '/' && url === '/time-tracking') {
            return ' selected-side-menu';
        }

        return ' sidebar-menu';
    }

    return (
        <>
            {authStore.selectedDepartmentId && (
                <div>
                    {permissions?.sidebarManagement?.sidebarViewBreakManagement &&
                        (
                            <Nav.Item>
                                <Nav.Link as={Link} to="/break-management"
                                          className={'d-flex' + isActiveMenu('/break-management')}>
                                    <div className='wrap-sider-icon-logo'>
                                        <img src={listReceiptCcon} alt="Icon"/>
                                    </div>
                                    <div>Break Management</div>
                                </Nav.Link>
                            </Nav.Item>
                        )
                    }

                    {permissions?.sidebarManagement?.sidebarViewHistorical &&
                        (
                            <Nav.Item>
                                <Nav.Link as={Link} to="/historical" className={'d-flex' + isActiveMenu('/historical')}>
                                    <div className='wrap-sider-icon-logo'>
                                        <img src={iconFolder} alt="Icon"/>
                                    </div>
                                    <div>Historical</div>
                                </Nav.Link>
                            </Nav.Item>
                        )
                    }

                    {permissions?.sidebarManagement?.sidebarViewTime &&
                        (
                            <Nav.Item>
                                <Nav.Link as={Link} to="/time" className={'d-flex' + isActiveMenu('/time')}>
                                    <div className='wrap-sider-icon-logo'>
                                        <img src={timeIcon} alt="Icon"/>
                                    </div>
                                    <div>Time</div>
                                </Nav.Link>
                            </Nav.Item>
                        )
                    }

                    {permissions?.sidebarManagement?.sidebarViewExpenses &&
                        (
                            <Nav.Item>
                                <Nav.Link as={Link} to="/expences" className={'d-flex' + isActiveMenu('/expences')}>
                                    <div className='wrap-sider-icon-logo'>
                                        <img src={expensesIcon} alt="Icon"/>
                                    </div>
                                    <div>Expenses</div>
                                </Nav.Link>
                            </Nav.Item>
                        )
                    }

                    {permissions?.sidebarManagement?.sidebarViewAbsences &&
                        (
                            <Nav.Item>
                                <Nav.Link as={Link} to="/leaves" className={'d-flex' + isActiveMenu('/leaves')}>
                                    <div className='wrap-sider-icon-logo'>
                                        <img src={leavesIcon} alt="Icon"/>
                                    </div>
                                    <div>Absences</div>
                                </Nav.Link>
                            </Nav.Item>
                        )
                    }

                    {permissions?.sidebarManagement?.sidebarViewRemoteWork &&
                        (
                            <Nav.Item>
                                <Nav.Link as={Link} to="/remote-work"
                                          className={'d-flex' + isActiveMenu('/remote-work')}>
                                    <div className='wrap-sider-icon-logo'>
                                        <img src={remoteWorkIcon} alt="Icon"/>
                                    </div>
                                    <div>Remote Work</div>
                                </Nav.Link>
                            </Nav.Item>
                        )
                    }

                    {permissions?.sidebarManagement?.sidebarViewUsers &&
                        (
                            <Nav.Item>
                                <Nav.Link as={Link} to="/users" className={'d-flex' + isActiveMenu('/users')}>
                                    <div className='wrap-sider-icon-logo'>
                                        <img src={usersIcon} alt="Icon"/>
                                    </div>
                                    <div>Users</div>
                                </Nav.Link>
                            </Nav.Item>
                        )
                    }

                    {permissions?.sidebarManagement?.sidebarViewGlobalConfig &&
                        //permissions?.manageGeneralSettings?.manageGeneralSettings && (
                        (
                            <>
                                <Nav.Item>
                                    <Nav.Link as={Link} to="/global-config"
                                              className={'d-flex' + isActiveMenu('/global-config')}>
                                        <div className='wrap-sider-icon-logo'>
                                            <img src={keyIcon} alt="Icon"/>
                                        </div>
                                        <div>Global Config (test)</div>
                                    </Nav.Link>
                                </Nav.Item>
                            </>
                        )
                    }
                </div>
            )}

            {permissions?.sidebarManagement?.sidebarViewDepartments &&
                (
                    <Nav.Item>
                        <Nav.Link as={Link} to="/departments" className={'d-flex' + isActiveMenu('/departments')}>
                            <div className='wrap-sider-icon-logo'>
                                <img src={departmentsIcon} alt="Icon"/>
                            </div>
                            <div>Departments</div>
                        </Nav.Link>
                    </Nav.Item>
                )
            }

            {permissions?.sidebarManagement?.sidebarViewTimeTracking &&
                (
                    <Nav.Item>
                        <Nav.Link as={Link} to="/time-tracking" className={'d-flex' + isActiveMenu('/time-tracking')}>
                            <div className='wrap-sider-icon-logo'>
                                <img src={clockTimeIcon} alt="Icon"/>
                            </div>
                            <div>Time tracking</div>
                        </Nav.Link>
                    </Nav.Item>
                )
            }
        </>
    );
});

export default SidebarListPages;
