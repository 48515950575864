import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTimerStore} from '../../stores/timerStore';
import iconStop from "../../assets/images/icons/icon-stop.svg";
import iconPlay from "../../assets/images/icons/icon-play.svg";
import TimeTrackerAdd from './TimeTrackerAdd';
import FlashMessage from "../common/FlashMessage";
import {useLocation} from "react-router-dom";

const TimeTracker = observer(() => {
    const store = useTimerStore();
    const [showAddModal, setShowAddModal] = useState(false);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState('success');
    const [recordDateTime, setRecordDateTime] = useState(null);
    const location = useLocation();
    const timeListStore = useTimerStore();

    const addScanTracker = async (model = null, scanType = 'in') => {
        try {
            await store.start(model, scanType);
            handleCloseModal();

            if (scanType === 'out' && location.pathname === '/time-tracking') {
                timeListStore.getTasksTrackingListToday();
            }

        } catch (error) {
            const errorData = error?.response?.data;
            setMessage(`Error!: ${error?.response?.data?.message}`);
            setVariant('danger');

            if (errorData?.data?.show_custom_time_popup) {
                if (errorData?.data?.record_date_time) {
                    setRecordDateTime(errorData?.data?.record_date_time);
                }
                setShowAddModal(true)
            } else {
                throw error;
            }
        }
    }

    const handleCloseModal = () => {
        setShowAddModal(false);
    };

    const resetErrorMessage = () => {
        setMessage(null)
        store.resetErrorsStore();
    }

    return (
        <>
            <div className={'tracker-block'}>
                <div className={store.intervalId ? 'time-block' : 'time-block tracker-disable'}>
                    {store.formattedTime}
                </div>
                {
                    store.intervalId ? (
                        <div className={'stop-block'}>
                            <div className={'stop-icon'}>
                                <img onClick={() => addScanTracker(null, 'out')}
                                     src={iconStop || ''}
                                     alt="stop"
                                     className="stop-icon"
                                />
                            </div>
                            <div className={'label-end'}>End time</div>
                        </div>) : (
                        <div className={'start-block'}>
                            <div className={'start-icon'}>
                                <img onClick={() => addScanTracker('in')}
                                     src={iconPlay || ''}
                                     alt="start"
                                     className="start-icon"
                                />
                            </div>
                            <div className={'label-start'}>Start time</div>
                        </div>
                    )
                }
            </div>
            <TimeTrackerAdd show={showAddModal} onClose={handleCloseModal} onAdd={addScanTracker}
                            recordDateTime={recordDateTime}></TimeTrackerAdd>
            <FlashMessage message={message} variant={variant} onClose={() => setMessage(null)}/>
            {!message &&
                <FlashMessage message={store.errors} variant={'danger'} onClose={() => resetErrorMessage()}/>
            }
        </>
    );
});

export default TimeTracker;
