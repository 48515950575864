import axiosConfig, {sendRequest} from './axiosConfig';
import {camelizeKeys, decamelizeKeys} from 'humps';

const taskTrackerApi = {
    getAll: async (params) => {
        const formatedParams = decamelizeKeys(params)
        const response = await axiosConfig.post('/tasks-tracking-list', {...formatedParams});
        return camelizeKeys(response.data);
    },
    getById: async (id) => {
        const response = await axiosConfig.get(`/users/${id}`);
        return camelizeKeys(response.data);
    },
    create: async (data) => {
        const response = await axiosConfig.post('/scan-users', {...data});
        return camelizeKeys(response.data);
    },
    update: async (id, data) => {
        const response = await sendRequest(`/update-user/${id}`, 'post', data, {}, 'multipart/form-data');
        return camelizeKeys(response.data);
    },
    delete: async (id) => {
        const response = await axiosConfig.delete(`/users/${id}`);
        return camelizeKeys(response.data);
    },
    getTasksTrackingListToday: async (params) => {
        const formatedParams = decamelizeKeys(params)
        const response = await axiosConfig.post('/get-today-scans-self', {...formatedParams});
        return camelizeKeys(response.data);
    },
    getAllUsersTasksTrackingListToday: async (params) => {
        const formatedParams = decamelizeKeys(params)
        const response = await axiosConfig.post('/get-today-scans-users', {...formatedParams});
        return camelizeKeys(response.data);
    },
    scan: async (params) => {
        const formatedParams = decamelizeKeys(params)
        const response = await axiosConfig.post('/scan', {...formatedParams});
        return camelizeKeys(response.data);
    }
};

export default taskTrackerApi;