import React, {useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Alert, Button, Form, Table} from "react-bootstrap";
import BackButton from "../common/BackButton";
import removeIcon from "../../assets/images/icons/remove-icon.svg";
import Loader from "../common/Loader";
import {useGlobalConfigStore} from "../../stores/globalConfigStore";
import {useNavigate, useParams} from "react-router-dom";
import Select from "react-select";
import RoleDelete from "./RoleDelete";
import FlashMessage from "../common/FlashMessage";
import {authStore} from "../../stores/authStore";

const RoleAdd = observer(() => {
    const store = useGlobalConfigStore();
    const {roleId} = useParams();
    const [roleName, setRoleName] = useState(store.role.name || '');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectCapability, setSelectCapability] = useState(1);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState('success');
    const initialErrors = {
        roleName: '',
    };
    const [errors, setErrors] = useState(initialErrors);

    useEffect(() => {
        resetStore();
        if (roleId) {
            store.loadCapabilities(roleId)
        } else {
            store.loadCapabilities()
        }
    }, []);

    useEffect(() => {
        setRoleName(store.role.name || '');
    }, [store.role.name]);

    useEffect(() => {
        const selected = store.usersWithRole.map(user => ({
            value: user.id,
            label: user.username,
        }));
        setSelectedUsers(selected);
    }, [store.usersWithRole]);


    const resetStore = () => {
        store.resetSort();
        setMessage(null);
        setVariant('success');
        setSelectCapability(1)
        setSelectedUsers([])
        setRoleName(store.role.name || '')
        store.resetStore();
    }

    const handleToggle = (capabilityId, permissionId) => {
        store.togglePermission(capabilityId, permissionId);
    };

    const handleDelete = async () => {
        if (roleId) {
            setIsSubmitting(true);
            try {
                await store.deleteModel(roleId);
                navigate(-1)
            } catch (error) {
                console.error('Failed to delete model:', error);
            } finally {
                setIsSubmitting(false);
                setShowDeleteModal(false);
            }
        }
    };

    const handleDeleteClick = () => {
        setShowDeleteModal(true);
    };

    const handleChangeRoleName = (event = '') => {
        const value = event?.target?.value ?? '';
        setRoleName(value);
        store.changeRoleName(value);
    };

    const handleSelectChange = (selectedOptions) => {
        setSelectedUsers(selectedOptions || []);
    };

    const userOptions = store.allUsers.map(user => ({
        value: user.id,
        label: user.username,
    })) || [];

    const validateForm = async () => {
        const validationPromises = validateField('roleName', store.role.name ?? '')
        const validationResults = await Promise.all(validationPromises.length ? validationPromises : [validationPromises]);
        return validationResults.every(error => !error);
    }

    const validateField = (roleName, value) => {
        return new Promise((resolve) => {
            let errorMessage = '';
            switch (roleName) {
                case 'roleName':
                    if (!value) errorMessage = 'Role Name is required';
                    else if (value.length > 120) {
                        errorMessage = 'Other expenses description must be less than or equal to 240 characters';
                    }
                    break;
                default:
                    break;
            }
            setErrors(prevErrors => ({...prevErrors, [roleName]: errorMessage}));
            resolve(errorMessage);
        });
    };

    const handleCloseModal = () => {
        setShowDeleteModal(false);
    };

    const handleBlur = (fieldName, value) => {
        validateField(fieldName, value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        store.userIds = selectedUsers.map(item => item.value);
        const isValid = await validateForm();
        if (isValid) {
            if (roleId) {
                await handleEdit(store.role.id, store);
            } else {
                await handleAdd(store);
            }
            setErrors(initialErrors);
        } else {
            console.log('Form validation failed');
        }
    };

    const handleEdit = async (id, modelData) => {
        try {
            setIsSubmitting(true);
            const userRoleId = authStore.getRoleId();

            await store.editModel(id, modelData);
            setMessage('Success!');
            setVariant('success');

            if (userRoleId === id) {
                authStore.fetchUser()
            }

        } catch (error) {
            console.error('Failed to edit model:', error);
            setMessage(`Error!: ${store.errors}`);
            setVariant('danger');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleAdd = async (modelData) => {
        try {
            setIsSubmitting(true);
            await store.addModel(modelData);
            setMessage('Success!');
            setVariant('success');

            authStore.fetchUser();
            navigate('/global-config')
        } catch (error) {
            console.error('Failed to edit model:', error);
            setMessage(`Error!: ${store.errors}`);
            setVariant('danger');
        } finally {
            setIsSubmitting(false);
        }
    };

    const resetErrorMessage = () => {
        setMessage(null)
        store.resetErrorsStore();
    }

    return (
        <>
            <div className={'wrap-title-page d-flex align-content-center justify-content-between'}>
                <h2 className={'page-title mb-3'}>
                    <BackButton className={'page-title'} label={'Add New Role'}/>
                </h2>
                <div className={'d-flex align-items-center'}>
                    {roleId && (<div className={'d-flex align-items-center me-2'}>
                            <div className={'d-flex align-items-center pe-2 cursor-pointer'}>
                                <a><img src={removeIcon} onClick={() => handleDeleteClick()} alt="remove"/></a>
                            </div>
                            <div className={'custom-remove-label cursor-pointer'}>
                                <a className={'custom-remove-label cursor-pointer'}
                                   onClick={() => handleDeleteClick()}>Remove</a>
                            </div>
                        </div>
                    )}
                    <div className={'me-2'}>
                        <Button className={'button-custom cancel-button'}
                                onClick={((e) => handleSubmit(e))}>Cancel</Button>
                    </div>
                    <div className={'me-2'}>
                        <Button className={'button-custom save-button'} onClick={((e) => handleSubmit(e))}>Save</Button>
                    </div>
                </div>
            </div>
            {store.errors && store.errors.message && (
                <Alert variant="danger" className="mt-3">
                    {store.errors.message}
                </Alert>
            )}
            <div className={'relative'}>
                <div className={'d-flex mb-3'}>
                    <Form.Group className={'w-50 me-2 align-content-end'} controlId="formRoleName">
                        <Form.Label className={'label-in-modal d-flex justify-content-start'}>Role Name: </Form.Label>
                        <Form.Control
                            className={'filter-up-table-second'}
                            type="text"
                            placeholder="Role Name"
                            value={roleName}
                            onChange={handleChangeRoleName}
                            isInvalid={!!errors.roleName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.roleName}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={'w-50'} controlId="assign-to">
                        <Form.Label className={'label-in-modal d-flex justify-content-start'}>Assign to: </Form.Label>
                        <Select
                            isMulti
                            value={selectedUsers}
                            onChange={handleSelectChange}
                            options={userOptions}
                            className="basic-multi-select custom-multi-select"
                            name="selectedUsers"
                            classNamePrefix="select"
                        />
                    </Form.Group>
                </div>
                {store.isFirstLoadPage === true ? (<Loader/>) : (
                    <div>
                        {store.loading === true ? (<Loader/>) : ''}
                        <div className={'d-flex'}>
                            <div>
                                <Table className={'list-table left-block-table'}>
                                    <tbody>
                                    {store?.capabilities?.map((item, index) => (
                                            <tr key={item.id} className={'table-head-tools'}>
                                                <td className={selectCapability === item.id ? 'first-col-left-table selected-block' : 'first-col-left-table'}
                                                    onClick={() => setSelectCapability(item.id)}>
                                                    <div className='wrap-left-table-icon-logo'>{item?.name}</div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                            <div className={'w-100'}>
                                <Table className={'list-table right-block-table'} hover>
                                    <thead className={'thead-table-global-config'}>
                                    <tr className={'no-hover'}>
                                        <th>
                                            <div>Capability</div>
                                        </th>
                                        <th>
                                            <div>Status</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {store?.capabilities[selectCapability - 1]?.permissions?.map((item) =>
                                        <tr key={item?.id}>
                                            <td scope="col">{item?.name}</td>
                                            <td>
                                                {item.status}
                                                {item?.radioFieldName ?
                                                    (<Form.Check
                                                            type="radio"
                                                            id={`custom-switch-${item.id}`}
                                                            defaultChecked={Boolean(item.status)}
                                                            name={'onePermissions'}
                                                            onClick={() => handleToggle(selectCapability, item.id)}
                                                        />
                                                    )
                                                    :
                                                    (<Form.Check
                                                        type="switch"
                                                        id={`custom-switch-${item.id}`}
                                                        checked={Boolean(item.status)}
                                                        onChange={() => handleToggle(selectCapability, item.id)}
                                                    />)
                                                }
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <RoleDelete
                show={showDeleteModal}
                onClose={handleCloseModal}
                onDelete={handleDelete}
                isSubmitting={isSubmitting}
            />
            <FlashMessage message={message} variant={variant} onClose={() => resetErrorMessage()}/>
            {!message &&
                <FlashMessage message={store.errors} variant={'danger'} onClose={() => resetErrorMessage()}/>
            }
        </>
    );
});

export default RoleAdd;
